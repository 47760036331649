
.icon-check-box {
    width: 30px;
    .check {
        color: var(--v-primary-base);
    }

    &--secondary {
        .check {
            color: var(--v-secondary-base);
        }
    }
}
