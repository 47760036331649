
.concierge-area{
    --padding-bottom: 40px;
    padding-bottom: var(--padding-bottom);
    position: relative;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: calc(var(--padding-bottom) * 2);
        bottom: 0;
        left: 0;
        background-color: var(--v-primary-base);
        z-index: -1;
    }
}
.concierge-img{
    width: calc(100% + var(--container-gutter));
    position: absolute;
    bottom: 0;
    right: 0;
    &-wrap{
        max-width: 430px;
        width: 100%;
        position: relative;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .concierge-area{
        --padding-bottom: 80px;
    }
}
@media (min-width:1024px){
    .concierge-img{
        min-width: 400px;
    }
    .concierge{
        &__left{
            flex: 0 0 25%;
            max-width: 25%;
        }
        &__right{
            flex: 0 0 41.6666666667%;
            max-width: 41.6666666667%;
        }
    }
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .concierge-img{
        min-width: 498px;
    }
    .concierge{
        &__left{
            flex: 0 0 33.3333333333%;
            max-width: 33.3333333333%;
        }
        &__right{
            flex: 0 0 33.3333333333%;
            max-width: 33.3333333333%;
        }
    }
}

