
.center-layout-row {
    --grid-gutter-center: 10px;
    --aside-width: 240px;
    @media (min-width: 1400px) {
        --grid-gutter-center: 14px;
    }
    @media (min-width: 1600px) {
        --grid-gutter-center: 14px;
    }
    @media (min-width: 1700px) {
        --grid-gutter-center: 20px;
    }
    @media (min-width: 1800px) {
        --grid-gutter-center: 30px;
    }
    margin: calc(var(--grid-gutter-center) * -1) !important;
    > [class*="col"] {
        padding: var(--grid-gutter-center) !important;
    }

    &__aside,
    &__contents {
        flex: 0 0 100%;
        max-width: 100%;
    }
    &__aside-inner {
        width: 100%;
        margin-bottom: 20px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .center-layout-row {
        &__aside {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        &__aside-inner {
            width: var(--aside-width);
            margin-bottom: 0;
        }
        &__contents {
            flex-basis: 0;
            flex-grow: 1;
            max-width: calc(100% - var(--aside-width) - (var(--grid-gutter-center) * 2));
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .center-layout-row {
        &__aside,
        &__contents {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &__aside-inner {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}
@media (min-width: 1400px) {
    .center-layout-row {
        &__aside {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        &__aside-inner {
            margin-bottom: 0;
        }
        &__contents {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}
