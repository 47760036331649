
.v-data-table.text-center > .v-data-table__wrapper > table > thead > tr > th {
    text-align: center;
}
.v-data-table--default.v-data-table--border {
    > .v-data-table__wrapper > table {
        > tbody,
        > thead {
            > tr {
                > th,
                > td {
                    line-height: 1.35;
                    padding: 8px;
                }
            }
        }
    }
}
